// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-school-detail-js": () => import("./../src/templates/school-detail.js" /* webpackChunkName: "component---src-templates-school-detail-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-de-cookies-js": () => import("./../src/pages/politica-de-cookies.js" /* webpackChunkName: "component---src-pages-politica-de-cookies-js" */),
  "component---src-pages-politica-de-privacidad-js": () => import("./../src/pages/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-js" */)
}

